@charset "utf-8";
html.fp-enabled, .fp-enabled body { margin: 0; padding: 0; overflow: hidden; -webkit-tap-highlight-color: rgba(0,0,0,0) }
#superContainer { height: 100%; position: relative; -ms-touch-action: none; touch-action: none }
.fp-section { position: relative; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box }
.fp-slide { float: left }
.fp-slide, .fp-slidesContainer { height: 100%; display: block }
.fp-slides { z-index: 1; height: 100%;  position: relative; -webkit-transition: all .3s ease-out; transition: all .3s ease-out }
.fp-section.fp-table, .fp-slide.fp-table { display: table; table-layout: fixed; width: 100% }
.fp-tableCell { display: table-cell; vertical-align: middle; width: 100%; height: 100% }
.fp-slidesContainer { float: left; position: relative }
/*
.fp-controlArrow { position: absolute; z-index: 4; top: 50%; cursor: pointer; width: 0; height: 0; border-style: solid; margin-top: -38px; -webkit-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0) }
.fp-controlArrow.fp-prev { left: 15px; width: 0; border-width: 38.5px 34px 38.5px 0; border-color: transparent #fff transparent transparent }
.fp-controlArrow.fp-next { right: 15px; border-width: 38.5px 0 38.5px 34px; border-color: transparent transparent transparent #fff }
*/
.fp-scrollable { overflow: scroll }
.fp-notransition { -webkit-transition: none !important; transition: none !important }
#fp-nav { position: fixed; z-index: 100; margin-top: -32px; top: 50%; opacity: 1; -webkit-transform: translate3d(0, 0, 0);display: none}
#fp-nav.right { right: 55px }
#fp-nav.left { left: 17px }
.fp-slidesNav { position: absolute; z-index: 4; left: 50%; opacity: 1 }
.fp-slidesNav.bottom { bottom: 17px }
.fp-slidesNav.top { top: 17px }
#fp-nav ul, .fp-slidesNav ul { margin: 0; padding: 0 }
#fp-nav ul li, .fp-slidesNav ul li { display: block; width: 14px; height: 3px; margin: 7px; position: relative;margin-top: 15px; }
.fp-slidesNav ul li { display: inline-block }
#fp-nav ul li a, .fp-slidesNav ul li a { display: block; position: relative; z-index: 1; width: 100%; height: 100%; cursor: pointer; text-decoration: none }
#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li:hover a.active span {width: 39px; margin: -6px 0 0 -6px;background: #002067;}
#fp-nav ul li a span, .fp-slidesNav ul li a span {background: #717171; position: absolute;z-index: 1; height: 2px; width: 24px; border: 0;right: 0; top: 50%; margin: -6px 0 0 -6px; -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out ;}
/*#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span { width: 10px; height: 10px; margin: -5px 0 0 -5px ;}*/
#fp-nav ul li .fp-tooltip { position: absolute; top: -2px; color: #fff; font-size: 14px; font-family: arial, helvetica, sans-serif; white-space: nowrap; max-width: 220px; overflow: hidden; display: block; opacity: 0; width: 0 }
#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active+.fp-tooltip { -webkit-transition: opacity .2s ease-in; transition: opacity .2s ease-in; width: auto; opacity: 1 }
#fp-nav ul li .fp-tooltip.right { right: 20px }
#fp-nav ul li .fp-tooltip.left { left: 20px }
.fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell { height: auto !important }


@media screen and (max-width: 768px) {
    .index .section {
        height: auto!important;
    }
  }

@media print {
html.fp-enabled, .fp-enabled body { overflow: visible !important; height: auto !important }
html { background: #fff; width: auto; height: auto; overflow: visible }
body { background: #fff; font-size: 20pt; width: auto; height: auto; border: 0; margin: 0 5%; padding: 0; overflow: visible; float: none !important }
#fp-nav, .fp-slidesNav, .fp-controlArrow { display: none !important }
h1 { font-size: 28pt !important }
h2 { font-size: 24pt !important }
h3 { font-size: 22pt !important }
h4 { font-size: 22pt !important; font-variant: small-caps }
h5 { font-size: 21pt !important }
h6 { font-size: 20pt !important; font-style: italic }
.fp-section, .fp-tableCell, .slimScrollDiv, .fp-scrollable { width: 100% !important; height: auto !important; display: block !important; position: relative; float: none !important }
.fp-slidesContainer { -webkit-transform: none !important; -ms-transform: none !important; transform: none !important; -webkit-transition: none !important; -moz-transition: none !important; -ms-transition: none !important; transition: none !important }
.fp-slides, .fp-slidesContainer { height: auto !important; width: 100% !important; float: none !important; overflow: hidden !important }
.fp-slides { height: 800px !important; width: 800px !important }
.fp-slide { float: none !important; width: 100% !important; position: static !important }
.fullpage-wrapper { float: none !important; -webkit-transform: none !important; -ms-transform: none !important; transform: none !important; -webkit-transition: none !important; -moz-transition: none !important; -ms-transition: none !important; transition: none !important; position: static !important; width: auto !important; height: auto !important; left: 0 !important; top: 0 !important; margin-left: 0 !important; margin-top: 0 !important; padding: 0 !important; zoom: 1 !important; overflow: visible !important; display: block !important; text-align: left !important }
.fp-section { page-break-after: always !important }
.fp-slide { page-break-after: always !important }
}