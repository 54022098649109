@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'OPPOSans-B';
  src: url('https://ziti.dongbaqu.com/guanzhong/OPPOSans-B.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-H';
  src: url('https://ziti.dongbaqu.com/guanzhong/OPPOSans-H.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-L';
  src: url('https://ziti.dongbaqu.com/guanzhong/OPPOSans-L.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-M';
  src: url('https://ziti.dongbaqu.com/guanzhong/OPPOSans-M.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-R';
  src: url('https://ziti.dongbaqu.com/guanzhong/OPPOSans-R.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'OPPOSans-R';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: #202020;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
html {
  &::-webkit-scrollbar {
    width: 4px;
}
&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0.2;
    background: #c9a063;
}
&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: fade(#fff, 30%);
}
}
img {
  max-width: fit-content;
  display: block;
  width: 100%;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1470px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1260 {
    max-width: 1260px;
  }
  &.container-1200 {
    max-width: 1200px;
  }
  &.container-1100 {
    max-width: 1100px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1330px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
.nei-banner {
  @include res(margin-top,0,(md:60px));
  position: relative;
  @include res(height,auto,(xs:300px));
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  .nei-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0,0,0,.5);
    @include res(height,80px,(3 / 4));
    @include res(line-height,80px,(3 / 4));
    overflow-y: hidden;
    overflow-x: auto;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(width,auto,(xs:fit-content));
      @include res(padding-left,0,(xs:30px));
      @include res(padding-right,0,(xs:30px));
      li {
        white-space: nowrap;
        height: 100%;
        @include res(margin-right,60px,(1 / 3));
        @include res(font-size,18px,(16 / 18));
        color: #ffffff;
        transition: all .3s;
        position: relative;
        span {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border: 14px solid;
          border-color: transparent transparent #fff transparent;
        }
        &:hover,&.active {
          font-family: OPPOSans-M;
          color: #c9a063;
          span {
            display: block;
          }
        }
      }
    }
  }
}
.details-banner {
  height: 120px;
  img {
    max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}
.title {
  h2 {
      font-family: OPPOSans-M;
      @include res(font-size,73px,(lg:0.73rem,xs:30px));
      @include res(letter-spacing,-2px,(1 / 2));
      color: #c9a063;
      opacity: 0.15;
  }
  h3 {
      font-family: OPPOSans-H;
      @include res(font-size,36px,(lg:0.36rem,xs:24px));
      letter-spacing: -1px;
      color: #3c4242;
  }
}
.nei-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include res(margin-top,30px,(1 / 2));
  font-size: 14px;
  color: #7b7b7b;
  transition: all .3s;
  a {
    &:hover,&.active {
      font-family: OPPOSans-M;
      color: #c9a063;
    }
  }
  span {
    @include res(margin-left,15px,(2 / 3));
    @include res(margin-right,15px,(2 / 3));
    &:last-child {
      display: none;
    }
  }
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}