@import "../../assets/styles/utils";
footer {
    width: 100%;
    @include res(height,460px,(xs:auto));
    background: #fff;
    @include res(padding-top,73px,(20 / 73));
    @include res(padding-bottom,112px,(30 / 112));
    @include res(font-size,16px,(14 / 16));
    line-height: 30px;
    color: #3c4242;
    .container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @include res(flex-wrap,nowrap,(xs:wrap));
        .left {
            ul {
                @include res(margin-top,35px,(4 / 7));
            }
        }
        .right {
            ul {
                text-align: center;
                display: flex;
                align-items: center;
                p {
                    margin-top: 13px;
                }
                li {
                    &:first-child {
                        @include res(margin-right,30px);
                        
                    }
                }
            }
        }
    }
}

.right_nav{ 
    bottom:100px; 
    position:fixed; 
    text-align:left;
    overflow: hidden;
    z-index:999;
    right: -95px; 
    transition: all .3s;  
}
.right_nav li{
    background-color: rgba(14,4,8,.11);
    margin:4px 0;
    @include res(padding,20px);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor:pointer;
    position:relative;
    
}
.right_nav li .iconBox{
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    
}
.right_nav li img{
    width: 30px;
    height:30px;
}
.right_nav li h4{
    color: #C9A063;
    font-size:14px;
    margin:2px 0;
    margin-left: 15px;
}
.right_nav li:hover{
    background:#cf1322;
    h4 {
        color: #fff;
    }
}
.right_nav li .hideBox{
    display: none;
    position: absolute;
    right:150px;
    padding:10px;   
    padding-right: 15px; 
    top:-10px;
    z-index: 999;
}
.right_nav li .hideBox .hb{
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px 20px;
    border-top: 2px solid #C9A063;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.right_nav li .hideBox .hb h5{
    padding: 5px 0;
    border-bottom: 2px solid #ccc;
    color: #C9A063;
    @include res(font-size,16px,(14 / 16));
    font-weight: 400;
    display: block;
    white-space: nowrap;
}
.right_nav li .hideBox .hb p {
    margin-top: 5px;
    @include res(font-size,18px,(16 / 18));
    font-weight: 700;
    white-space: nowrap;
}
.right_nav li .hideBox .hb .qqtalk{
    margin-top: 10px;
}
.right_nav li .hideBox .hb .qqtalk a{
    display: flex;
    align-items: center
}
.right_nav li .hideBox .hb .qqtalk img{
    width: 25px;
    height:25px;
}
.right_nav li .hideBox .hb .qqtalk p{
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    margin-top: -5px;
    margin-left: 5px;
}
.right_nav li:nth-child(2) .hideBox img{
    width:140px;
    height: 140px;
    margin-top: 10px;
}