@import "../../assets/styles/utils";
header {
    width: 100%;
    @include res(height,90px,(md:60px));
    @include res(position,fixed);
    top: 0;
    z-index: 1000;
    @include res(background,transparent,(md:#fff));
    border-bottom: 1px solid rgba(255,255,255,.6);
    &.head1 {
        border-bottom: 1px solid rgba(0,0,0,.6);
        background: #fff;
        .logo {
            a {
                div {
                    color: #202020;
                    span {
                        background: #202020;
                    }
                }
            }
            .img1 {
                display: none;
            }
            .img2 {
                display: flex;
                
            }
        }
        .container {
            .nav {
                ul {
                    li {
                        span {
                            &:after {
                                background: #c9a063;
                            }
                        }
                        a {
                            color: #202020;
                        }
                    }
                }
            }
        }
    }
    .logo {
        @include res(height,50px);
        a {
            display: block;
            height: 100%;
            div {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 14px;
                p {
                    margin-top: 20px;
                }
                span {
                    display: block;
                    width: 1px;
                    height: 32px;
                    background: #fff;
                    margin: 0 8px;
                }
            }
        }
        .img1 {
            @include res(display,flex,(md:none));
        }
        .img2 {
            @include res(display,none,(md:flex));
        }
        img {
            display:block;
            height: 100%;

        }
    }
    .container {
        height: 100%;
        .pc-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
        
        .nav {
            height: 100%;
            ul {
                display: flex;
                align-items: center;
                height: 100%;
                li {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    &:hover,&.active {
                        a {
                            color: #c9a063;
                            span {
                                &:after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                      }
                    position: relative;
                    @include res(margin-left, 50px, (lg:30px,mmd:15px));
                    a {
                        display: inline-block;
                        font-family: OPPOSans-M;
                        @include res(font-size,16px,(14 / 16));
                        color: #fff;
                        transition: all .3s;
                    }
                    .search {
                        margin-top: 4px;
                        cursor: pointer;
                    }
                    span {
                        position: relative;
                        display: flex;
                        align-items: center;
                        &:after {
                            content: "";
                            width: 100%;
                            height: 2px;
                            display: block;
                            background-color: #fff;
                            position: absolute;
                            bottom: -36px;
                            left: 0;
                            z-index: 1;
                            transition: all .3s;
                            transform-origin: center;
                            transform: scaleX(0);
                        }
                    } 
                    .navchild {
                        align-items: center;
                        height: 2.6875vw;
                        position: absolute;
                        left: auto;
                        right: 0;
                        top: 85px;
                        z-index: 9999;
                        padding-top: 5px;
                        display: none;
                        dl {
                            display: flex;
                            dt {
                                white-space: nowrap;
                                a {
                                    color: #fff;
                                    font-size: 16px;
                                    transition: all .3s;
                                    &:hover {
                                        color: #c9a063;
                                    }
                                }
                                margin-right: 20px;
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    &:nth-child(-n+4) {
                        .navchild {
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
        .phone {
            display: none;
            position: relative;
            z-index: 1000;
        }
        .mob-nav {
            width: 100%;
            height: 100vh;
            background-color: #f7f7f7;
            position: fixed;
            top: 60px;
            right: 100%;
            z-index: 99;
            color: #c9a063;
            box-sizing: border-box;
            overflow-y: auto;
            transition: .4s cubic-bezier(.77,0,.175,1);
            padding-top: 30px;
            .nLi {
                &.active {
                    img {
                        transform: rotate(90deg);
                    }
                }
                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(0,73,130,.3);
                    margin: 0 20px;
                    // padding: 0 20px;
                    .arrow {
                        width: 45px;
                        display: flex;
                        justify-content: end;
                    }
                    a {
                        line-height: 45px;
                        font-size: 16px;
                        width: fit-content;
                        display: block;
                    }
                }
            }
            &.active {
                right: 0;
            }
            .sub {
                display: none;
                border-bottom: 1px solid rgba(0,73,130,.3);
                li {
                    a {
                        display: block;
                        padding-left: 20px;
                        font-size: 16px;
                        line-height: 40px;
                        color: #c9a063;
                        
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    header {
        .nav {
            display: none;
        }
        .container {
            .phone{
                display: flex;
                align-items: center;
                .search {
                    margin-right: 20px;
                }
            }
        }
    }
}

.search-box {
    display: none;
    @include res(width,800px,(sm:90%));
    margin: auto;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: center;
    .content {
        width: 100%;
        @include res(height,60px,(sm:50px));
    }
    input {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        display: block;
        border-bottom: 1px solid rgba(255,255,255,.35);
        background: transparent;
        font-family: SourceHanSansCN-Light;
        @include res(font-size,20px,(16 / 20));
        letter-spacing: 1px;
        color: #ffffff;
        &::placeholder {
            color: #fff;
        }

    }

    img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        cursor: pointer;
    }
}
.header-bg {
    width: 100%;
    height: 2.6875vw;
    position: fixed;
    @include res(top,90px,(md:80px));
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, .15);
    display: none;
}