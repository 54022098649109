@import "../../assets/styles/utils";
.pages {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .page-btn {
        @include res(margin-right,25px,(3 / 5));
        span {
            color: #909090;
            font-size: 30px;
            transition: all .3s;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            span {
                color: #c9a063;
            }
        }
    }
    .paging {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(width,60px,(2 / 3));
            @include res(height,60px,(2 / 3));
            border-radius: 50%;
            @include res(font-size,16px,(14 / 16));
            color: #202020;
            @include res(margin-right,25px,(3 / 5));
            transition: all .3s;
            &:hover,&.active {
                background-color: #c9a063;
                color: #fff;
            }
        }
    }
}